import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components"
import SEO from "../../components/seo.js"
import Layout from "../../components/layout.js"
import { navigate } from "gatsby"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

import { InPageNav } from "../../components/navbar.js"

import {
  HeroTitleH1,
  SectionTitle,
  SectionDescription,
} from "../../components/typography.js"
import { Section } from "../../components/sections/sections.js"

const Row = styled(Section)`
  padding-top: 70px;
  padding-bottom: 70px;
`

const RowContent = styled.div`
  opacity: 0.1;
  transition: opacity 0.3s ease-in;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`
const Title = styled(SectionTitle)`
  font-size: 500;
`
const NumberTitle = styled(Title)`
  font-size: 94px;
  font-weight: 600;
`

const howClassWorksContent = [
  {
    title: "Submit Your Transcripts & CV",
    description:
      "As a base we need to study these documents to look from problems like low grades, absences, low test-prep. coures, & lack of experiences, that we may need to address in your application.",
  },
  {
    title: "Submit Your Drafts",
    description:
      "For each of the responses you need edited, you need to submit a pre-written draft that we can work on -- we don't write things from scratch for you. At this point, it's on our team to get working on your stuff!",
  },
  {
    title: "Understanding Your Story",
    description:
      "Based on your documents, and our conversations with you, take in your story. We're careful not to take what you've said at face-value but we `pop the hood` and find out what drives you and what the admissions comittee would want to hear from you! ",
  },
  {
    title: "Evaluating & Modifying The Narrative",
    description: `For a response, we assess the story you're telling the reader and poke holes through them to see if it can withstand scrutiny. Imagine if someone on the admissions panel "just doesn't buy it" -- we prevent those cases. In the event your story needs to be modified, we do that`,
  },
  {
    title: "Removing Your Assumptions",
    description: `Sometimes unknowingly, people make massive assumptions about the things they're saying, and at times those assumptions offend people. For example, if a student wrote that they wanted to pursue medicine and not nursing, becuase they wanted to help people -- like it or not, someone can read it as "nursing doesn't help people" or worse "nursing is useless". We fix that.`,
  },
  {
    title: "Response Enrichment",
    description:
      "A lot of the times, you forget your goal -- it's not juuust to answer the questions asked, but to show the admissions panel who you are. How can one do that? One way is to have tie in your story, and another is engrain your experiences! If a response doesn't have that quality, or is unconvincing, or bland -- we add a lot more stuff to it while keeping it under the word count!",
  },
  {
    title: "Standing Out!",
    description: `This is where true artistry shines out: We know the sort of responses the admissions panel receives; the stories they hear in almost every application, and what they're kinda dying to hear! We give them exactly that -- whether that's by tying in the right motivation, worded juuust the right way, or it's recapping an experience that you mentioned elsewhere and tying that in. Reading your app., we make it so that the person reading your response loves it!`,
  },
  {
    title: "Making It Wholesome",
    description: `It doesn't just end at writing a coherent response: we have to look across your responses and find out if we're wasting space by repeating things or over-explaining certain facets. It's a subtle art, but we want their impression of you to keep building and them to stay into it. Imagine this: if somone went home and lay in their bed thinking about your story -- isn't that an almost automatic acceptance? Your app. would've been so good that they didn't forget it all day!`,
  },
  {
    title: "Cutting The Length",
    description:
      "This is usually the hardest part for everyone: If you had unlimited words to express yourself, then you could tell the admissions panel everything; But, reduced lenght threatens the integrity, quality and the multifactedness of a response. Don't worry though -- our experts are all over this, and historically, we've never run into a response that we couldn't shorten",
  },
  {
    title: "Sending It Back To You",
    description:
      "At this point, we send you your responses so you can take your time to absorb them, and ask follow up questions if you have any. ",
  },
  {
    title: "Question-answering & Making Fixes",
    description:
      "We basically answer your questions, and finalize your response so you can submit it!",
  },
  {
    title: "Let's Submit!",
    description:
      "That's it! You can save your response and submit your application whenever <3 ",
  },
]
const HowItWorks = () => {
  const [positions, setPositions] = useState([])
  const [currentHighlight, setHighlight] = useState(0)
  const itemRefs = useRef([])

  useEffect(() => {
    let positionsArray = itemRefs.current.map((el, index) => {
      if (typeof window !== "undefined") {
        return window.pageYOffset + el.getBoundingClientRect().top
      }
    })
    setPositions(positionsArray)
  }, [])

  const handleRefs = (el, index) => {
    if (itemRefs.current.length < howClassWorksContent.length)
      itemRefs.current.push(el)
  }

  const positionLocator = (
    currPos,
    scrollingDownBool,
    upperRange,
    lowerRange
  ) => {
    positions.forEach((row, index) => {
      while (
        positions[index] &&
        positions[index] > upperRange &&
        positions[index] < lowerRange
      ) {
        setHighlight(index)
        itemRefs.current[currentHighlight].style.opacity = "1"
        if (
          index - 1 > -1 &&
          itemRefs.current[index - 1].style.opacity === "1"
        ) {
          itemRefs.current[index - 1].style.opacity = "0.1"
        }
        if (
          index + 1 < positions.length &&
          itemRefs.current[index + 1].style.opacity === "1"
        ) {
          itemRefs.current[index + 1].style.opacity = "0.1"
        }
        break
      }
    })
  }
  useScrollPosition(({ prevPos, currPos }) => {
    if (typeof window !== "undefined") {
      let currX = Math.abs(currPos.y)
      let prevX = Math.abs(prevPos.y)
      let upperRange =
        window.innerHeight * 0.3 || document.documentElement.clientHeight * 0.3
      let lowerRange =
        window.innerHeight * 0.7 || document.documentElement.clientHeight * 0.7
      // let currentItemPos = itemRefs.current[
      //   currentHighlight
      // ].getBoundingClientRect().top
      positionLocator(
        currX,
        currX > prevX,
        currX + upperRange,
        currX + lowerRange
      )
    }
  })
  const RedirectToPage = dest => {
    navigate(dest)
  }
  return (
    <Layout>
      <SEO title="How 99point9 MCAT Course Works" />
      <InPageNav
        pageTitle="How It Works"
        navItems={[
          { link: "/editing_applications", label: "Overview" },
          { link: "/editing_applications/how_it_works", label: "How It Works" },
          { link: "/editing_applications/schedule", label: "Class Schedule" },
        ]}
        clickCallback={RedirectToPage}
      />
      {/* <HeroTitleH1 align="center" float="center">HOW IT WORKS</HeroTitleH1> */}
      <Section>
        <HeroTitleH1 align="center" float="center">
          HOW IT WORKS
        </HeroTitleH1>
      </Section>
      <br />
      <br />
      <br />
      <br />
      {/* <CurvedBackground  style = {{fontSize: "1px"}}src={Curved} />
        <CurvedBackground  style = {{fontSize: "1px"}}src={Curved} /> */}

      {howClassWorksContent.map((item, index) => {
        return (
          <Row
            key={"row" + item.title + item.description + index}
            wide
            backgroundColor="rgb(230,230,230)"
          >
            <RowContent
              ref={el => handleRefs(el, index)}
              className={"animatedRow " + index}
            >
              <div>
                <Title>{item.title}</Title>
                <SectionDescription>{item.description}</SectionDescription>
              </div>
              <div>
                <NumberTitle>{index + 1}</NumberTitle>
              </div>
            </RowContent>
          </Row>
        )
      })}
    </Layout>
  )
}

export default HowItWorks
